import { AdMobService } from "./googleAdmob";
import { MetaAudienceService } from "./metaAudience";

export class AdService {

    private adMob: AdMobService;
    private audienceNetwork: MetaAudienceService;
    private currentInterstitialNetwork: string | null;
    private currentBannerNetwork: string | null;
    private lastInterstitialShown: number | null;

    private static instance: AdService;

    constructor() { 
        this.adMob = new AdMobService();
        this.audienceNetwork = new MetaAudienceService();
        this.currentInterstitialNetwork = 'meta';
        this.currentBannerNetwork = 'meta';
        this.lastInterstitialShown = null;

        if (AdService.instance) {
            return AdService.instance;
        }
        AdService.instance = this;
    }

    private isInterstitialAllowed(): boolean {
        if (!this.lastInterstitialShown) return true; // First time, allow showing the ad
        const now = Date.now();
        const fiveMinutes = 5 * 60 * 1000; // 5 minutes in milliseconds
        return (now - this.lastInterstitialShown) >= fiveMinutes;
    }

    async showInterstitial() {
        try{
            if (!this.isInterstitialAllowed()) {
                console.log("Skipping interstitial: 5-minute interval not reached");
                return;
            }

            await this.adMob.prepareInterstitial();
            this.currentInterstitialNetwork = await this.adMob.showInterstitial();

            if(this.currentInterstitialNetwork) {
                this.lastInterstitialShown = Date.now();
                return;
            }

            this.currentInterstitialNetwork = await this.audienceNetwork.showInterstitial();
            if(this.lastInterstitialShown){
                this.lastInterstitialShown = Date.now();
            }
        }catch(err){
            console.log("Err from showing interstitial: ",err)
        }
    }

    async showBanner(){
        try{
            this.currentBannerNetwork = await this.adMob.showBanner();

            if(this.currentBannerNetwork) return;

            this.currentBannerNetwork = await this.audienceNetwork.showBanner();
        }catch(err){
            console.log("Err from showing banner: ",err)
        }
    }

    async removeBanner(){
        if(this.currentBannerNetwork === 'meta'){
            await this.audienceNetwork.removeBanner();
        }else{
            await this.adMob.removeBanner();
        }
    }  
}
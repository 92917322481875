const config = {
    backendApi: "https://guitar.studioamk.com/api",
    socketApi: "",
    screenWidth: "414px",
    termsAndConditionsLink: "https://guitar.studioamk.com/.well-known/terms.html",
    privacyPolicyLink: "https://guitar.studioamk.com/.well-known/privacy.html",
    guideLink: "https://guitar.studioamk.com/.well-known/guide.html",
    videoHeight: Math.floor(window.innerHeight * ( 60 / 100 )),
    tiktok: {
        clientKey: "awqr9o0nmj2iscig",
        redirectUri: "https://guitar.studioamk.com"
    },
    google: {
        clientKey: "279036595578-3teqlksb3migb9t66dvlf9jmmju51skt.apps.googleusercontent.com",
        adMobAppId: "ca-app-pub-4674378923969357~4630516391",
        adMobInterstitialAdId: "ca-app-pub-4674378923969357/5014493921",
        adMobBannerAdId: "ca-app-pub-4674378923969357/2855170808"
    },
    facebook: {
        appId: "236520296473409",
        interstitialPlacementId: "497375973266663_501190509551876",
        bannerPlacementId: "497375973266663_501784346159159",
    },
    color: {
        primaryColor: "#2D333B",
        secondaryColor: "#8054DB",
        sideBarColor: "#22272E",
        mainColor: "#1C2128",
        footerBgColor: "#1F1F1F",
        pageSelectedColor: "grey",
        pageNameColor: "#474747",
        htmlColor: '#1A1A1A'
    }
}
export default config ;
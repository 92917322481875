/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import Skeleton from 'react-loading-skeleton';
import { Pagination, Autoplay } from 'swiper/modules';
import { ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import logo from "../../assets/images/logo-horizontal.png";
import {ReactComponent as SearchIcon} from "../../assets/icons/searchIcon.svg";
import {ReactComponent as NotiIcon} from "../../assets/icons/noti.svg";
import {ReactComponent as NotiAlertIcon} from "../../assets/icons/noti-alert.svg";
import { CollectionDataType } from '../../types';
import { getDecoded } from "../../utils";

type CarouselProps = {
    loading: boolean;
    existUnreadNoti: boolean;
    collections: CollectionDataType | undefined;
}

const Carousel: React.FC<CarouselProps> = ({loading, existUnreadNoti, collections})  => {
  const navigate = useNavigate();

  const handleViewClick = (c: CollectionDataType[0]) => {
    navigate('/home/collectionList',{state: {collectionId: c.id, collectionName: c.name}})
  }

  const handleSearchClick = () => {
    navigate('/home/search');
  }

  const handleProfileClick = (c: CollectionDataType[0],e : any) => {
    e.stopPropagation();
    if(c.song.user.user_id === getDecoded().userId){
      navigate('/profile');
      return;
    }
    const obj = {userId: c.song.user.user_id};
    navigate('/userProfile',{state: obj});
  }

  return (
    <div style={{position: "relative"}}>
        <>
          <div>
            <div style={{width: "100%",backgroundColor: 'transparent'}}>
              <div style={{ padding: "10px",display: 'flex', justifyContent: 'space-between',alignItems: "center"}}>
                <img src={logo} width={"114px"} height={"60px"}/>
                <div style={{display: 'flex',alignItems: 'center'}}>
                  <ButtonBase style={{marginRight: "30px"}} onClick={handleSearchClick}>
                    <SearchIcon/>
                  </ButtonBase>
                  <ButtonBase onClick={()=> {
                    navigate('/home/notiLists');
                  }}
                  style={{paddingRight: "10px", position: 'relative'}}
                  > 
                    {existUnreadNoti && <NotiAlertIcon style={{position: "absolute",left: "-5px",top: "-1px"}}/>}
                    <NotiIcon/>
                  </ButtonBase>
                </div>
              </div>
            </div>
          </div>
          {!loading && <img style={{position: "absolute",borderRadius: "0 0 0px 0px", height: "418px", width: "100%"}}
              src={collections ? collections[0].cover_image: ""}
              alt={collections ? collections[0].name : ""}
          />}
          <Swiper
              className="mySwiper"
              centeredSlides={true}
              autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination]}
          >
            {loading && 
              <Skeleton width='100%' height='418px' borderRadius="0 0 0px 0px"/>
            }
            {!loading && collections?.map(c => (
            <SwiperSlide key={c.id}>
              <div key={c.id} onClick={()=>handleViewClick(c)} style={{zIndex: 1000}}>
                <div style={{ position: 'relative',height: '418px' }}>
                  <div style={{position: 'absolute',top: "15px",width: "100%",display: 'flex',justifyContent: 'center'}}>
                      <h1 css={css`font-family: khyay !important ;`} style={{fontSize: "30px", fontFamily: "khyay"}}>{c.name}</h1>
                  </div>
                  <div style={{position: 'absolute',top: "120px",left: "30px",width: "100%"}}>
                    <div style={{position: 'relative'}}>
                      <img src={c.image}/>
                      <div style={{position: "absolute",top: "0px",display: 'flex',flexDirection: "column", width: "230px", marginLeft: "10px"}}>
                        <h1 css={css`font-family: sans-pro !important ;`} style={{fontSize: "25px", fontWeight: "normal", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "center", marginBottom: "10px", marginLeft: "5px",marginRight: "5px"}}>{c.song.name}</h1>
                        <div style={{marginLeft: "auto", marginRight: "auto", }}>
                          <div>
                            <img src={c.song.image_url} style={{height: "147px", width: "147px", borderRadius: "10px"}}/>
                            <div style={{display: 'flex',alignItems: 'center',zIndex: 4000}}>
                                <img src={c.song.user.avatar} style={{borderRadius: "20px",marginRight: "5px"}} width={"20px"} height={"20px"} onClick={(e) => handleProfileClick(c,e)}/>
                                <h5 className="custom-font" style={{color: "#565656", fontSize: "12px", fontWeight: "normal",lineHeight: "15px",margin: "0"}} onClick={(e) => handleProfileClick(c,e)}>{c.song.user.name}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
    </div>
  )
}

export default Carousel;
import React, { useEffect, useState } from 'react'
import { ButtonBase } from '@mui/material';
import Skeleton from 'react-loading-skeleton';

import {ReactComponent as DownArrow} from '../../assets/icons/down-arrow.svg'
import {ReactComponent as NotiAlert} from '../../assets/icons/noti-alert.svg'
import BackHeader from '../CommonComponents/BackHeader'
import { useStore } from '../../store'
import { NotiCard } from '../../types';
import { saveNotificationsToStorage, timeAgo } from '../../utils';
import { useNavigate } from 'react-router-dom';

export default function NotiList() {
  const {store, updateStoreFunc} = useStore();
  const notiLists = store.notiLists;
  const [selectedTag, setSelectedTag] = useState("");
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();

  const text = store.text;

  const handleTagSelect = (tag: string) => {
    setSelectedTag(tag)
  }

  const handleNotiClick = (notiId: number) => {
    const updatedNotifications = { ...notiLists };
    let clickedNoti : NotiCard | null = null;
    for (const [key, value] of Object.entries(updatedNotifications)) {
        updatedNotifications[key] = updatedNotifications[key].map((noti) => {
          if (noti.id === notiId) clickedNoti = noti;
          return ({
            ...noti,
            read: noti.id === notiId ? true : noti.read,
        })
        });;
    }
    updateStoreFunc("notiLists",updatedNotifications);
    saveNotificationsToStorage(updatedNotifications);
    
    if(clickedNoti){
      let data : any = JSON.parse((clickedNoti as NotiCard).data);
      if(data?.page?.name === "songDetail"){
        const songId = data?.page?.data?.songId;
        const chordId = data?.page?.data?.chordId;
        navigate("/songDetail",{state: {song_id: songId, chord_id: chordId}});
      }else if( data?.page?.name === "songRequest"){
        const songRequestId = data?.page?.data?.songRequestId;
        navigate(`/songRequests/detail`,{state: {songRequestId}});
      }
    }
  }


  useEffect(() => {
    if(notiLists){
      setSelectedTag(Object.keys(notiLists)[0]);
    }
  }, [])

  return (
    <div>
        <BackHeader/>
        <h1 style={{position: 'fixed', top: 0, left: "50%",transform: 'translate(-50%, 0)',color: "white", fontSize: "22px"}}>
          {text['Notification']}
        </h1>
        {
            loading ? 
            <Skeleton width={"100%"} height={"100%"} count={20} style={{marginBottom: "10px",paddingTop: "15px"}} />
            :
            <div>
                <div style={{marginLeft: "20px"}}>
                    <div style={styles.tagBar} className='remove-scrollbar'>
                        {notiLists && Object.keys(notiLists).map((tag, index: number) => (
                            <ButtonBase
                            key={index}
                            onClick={() => handleTagSelect && handleTagSelect(tag)}
                            style={{
                                ...styles.tagButton,
                                ...(tag === selectedTag ? styles.selectedTagButton : {}),
                            }}
                            >
                            {tag}
                            </ButtonBase>
                        ))}
                    </div>
                </div>
                {
                  notiLists && selectedTag && notiLists[selectedTag].length === 0 ? 
                  <div style={{color: 'gray', display: "flex", justifyContent: "center", fontStyle: "italic", marginTop: "7rem"}}>{text['No results found']}</div>
                  :
                  <div className="scrollable-y remove-scrollbar" style={{height: window.innerHeight - 120}}>
                      {notiLists && selectedTag && notiLists[selectedTag].map((n) => (
                          <NotificationCard key={n.id} notification={n} handleNotiClick={handleNotiClick}/>
                      ))}
                  </div>
                }
            </div>
        }
    </div>
  )
}


const styles = {
    tagBar: {
      display: 'flex',
      marginBottom: '15px',
      marginTop: "10px",
      width: '100%',
      overflowX: "scroll",
    } as React.CSSProperties,
    tagButton: {
      padding: '8px 12px',
      marginRight: '10px',
      borderRadius: '10px',
      border: 'none',
      backgroundColor: '#3A3A3C',
      color: '#FFFFFF',
      cursor: 'pointer',
      whiteSpace: 'nowrap' as const,
    } as React.CSSProperties,
    selectedTagButton: {
      backgroundColor: '#0A84FF',
    } as React.CSSProperties,
  };


  interface NotificationCardProps {
    notification: NotiCard;
    handleNotiClick: (notiId: number) => void
  }
  
  const NotificationCard: React.FC<NotificationCardProps> = ({ notification, handleNotiClick }) => {
    const [expanded, setExpanded] = useState(false);
    
    const cardStyle = {
      backgroundColor: notification.read ? '#1C1C1C' : '#2D2D2D',
      color: 'white',
      padding: '10px 20px 0 20px',
      cursor: 'pointer',
      position: 'relative',
    } as React.CSSProperties;
  
    const handleToggleExpand = () => {
      setExpanded(pre => !pre);
    };

    const notiWordLimit = 50;
  
    return (
      <div style={cardStyle} >
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
            <div style={{ fontWeight: 'bold', fontSize: '18px', color: "#3373EF" }} onClick={() => handleNotiClick(notification?.id)}>{notification?.title}</div>
            {/* <div style={{ fontSize: '10px', color: '#888888' }}>{notification.created_date}</div> */}
            <div onClick={handleToggleExpand} style={{ fontSize: '10px', color: '#888888' }}>{timeAgo(notification?.created_date)}</div>

        </div>
        <div style={{display: 'flex',justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{ fontSize: '12px', marginTop: '8px',borderStyle: "solid", borderColor: "#242424", borderWidth: "0 0 1px 0", paddingBottom: "10px", width: "100%" }} onClick={() => handleNotiClick(notification?.id)}>
              {expanded ? notification.body : notification.body.slice(0, notiWordLimit) + (notification?.body?.length > notiWordLimit ? '...' : '')}
            </div>
            {notification?.body?.length  > notiWordLimit && !expanded && <DownArrow onClick={handleToggleExpand}/>}
            {!notification?.read && <NotiAlert style={{width: "10px", height: "10px", position: 'absolute', top: '5px', right: '5px'}}/>}
        </div>
      </div>
    );
  };

import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonBase } from '@mui/material';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

import { SongRequestsDataType, SongRequestType } from '../../types';
import CustomImage from '../CommonComponents/CustomImage';
import {ReactComponent as ImgPlaceholder} from '../../assets/icons/image-placeholder.svg';
import { useStore } from '../../store';
import BackHeader from '../CommonComponents/BackHeader';
import { formatNumber, getDecoded } from '../../utils';
import {ReactComponent as UpVoteOnIcon} from '../../assets/icons/up-vote-on.svg';
import {ReactComponent as UpVoteOffIcon} from '../../assets/icons/up-vote-off.svg';
import {ReactComponent as DownVoteOnIcon} from '../../assets/icons/down-vote-on.svg';
import {ReactComponent as DownVoteOffIcon} from '../../assets/icons/down-vote-off.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import config from '../../config';
import CustomButton from '../CommonComponents/CustomButton';
import { message, Modal } from 'antd';
import { endpoints } from '../../api/endpoints';
import api from '../../api/apiClient';
import Loader from '../CommonComponents/Loader';

export default function SongRequestDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const songRequestId : number = location.state.songRequestId;
  const [s, setS] = useState<SongRequestType>();
  const [loading, setLoading] = useState(true);
  const [lyricsImgFullScreen, setLyricsImgFullScreen] = useState(false);
  const {store} = useStore();
  const text = store.text;

  const lyricsImgRef = useRef<any>();

  const onZoomUpdate = useCallback(({ x, y, scale }: any) => {
    const { current: img}: any = lyricsImgRef;
        if (img) {
        const value = make3dTransformValue({ x, y, scale });
        img.style.setProperty("transform", value);
        }
  }, []);

  const renderLyricsImg = () => {
    return (
      <>
        {
          lyricsImgFullScreen ?
          <div onClick={() => setLyricsImgFullScreen(false)} style={{ position: "fixed", top: 0, width: window.innerWidth > Number(config.screenWidth.split('px')[0]) ? config.screenWidth :  '100vw',height: "100vh", backgroundColor: "black",zIndex: 2000}}>
            <QuickPinchZoom onUpdate={onZoomUpdate} >
              <img ref={lyricsImgRef} src={s?.view?.chord?.img || ""} style={{height:'100vh'}} />
            </QuickPinchZoom>
          </div>
          :
          <div onClick={() => setLyricsImgFullScreen(true)} style={{display: 'flex', justifyContent: 'center', marginTop: "10px"}}>
              <CustomImage ref={lyricsImgRef} src={s?.view?.chord?.img || ""} height={'60vh'} skeletonWidth={window.innerWidth > Number(config.screenWidth) ? config.screenWidth :  '100vw'} style={{height:'60vh',objectFit: 'contain'}} />
          </div>
        }
      </>
    )
  }

  const handleUserClick = (userId: number) => {
    if(userId === getDecoded().userId){
        navigate('/profile');
        return;
    }
    const obj = {userId: userId};
    navigate('/userProfile',{state: obj});
  }

  const showModal = (name: string) => {
    const accept = name === 'accept';
    Modal.confirm({
        title: text['Are you sure?'],
        content: accept ? text['Accept Chord Image'] : text['Deny Chord Image'],
        okText: text['Yes'],
        cancelText: text['No'],
        centered: true,
        async onOk() {
            const status = accept ? 1 : 0;
            const params = {
                songId: s?.song?.id,
                chordId: s?.view?.chord?.id,
                status: status
            }
            const result : any = await api.post(endpoints.songRequest.songRequestAction, params);
            if(result.code === "200"){
                message.success(result.message);
                navigate("/songRequests");
            }else{
                message.error(result.message);
            }
        },
    });
  }

  const getData = async () => {
    const res: any = await api.get(endpoints.songRequest.getSongRequests + `?current=${1}&limit=10&filter=${songRequestId}`);
    setLoading(false);
    if(res.code !== "200") return;
    const data: SongRequestsDataType = res.data;
    setS(data.by[0]);
  }

  useEffect(() => {
    getData();
  },[])

  return (
    <div>
        <BackHeader/>
        <div className="scrollable-y remove-scrollbar" style={{height: store.screenHeight}}>
            {
                loading && 
                <Loader/>
            }
            {
                !s && !loading && 
                <div style={{display: 'flex', justifyContent: "center", fontStyle: "italic", color: 'gray'}}>
                    <h1>
                        Not Found
                    </h1>
                </div>
            }

            {s && !loading && 
            <>
                <div style={{marginBottom: "10px", padding: "0 20px"}}>
                    <div style={{display: 'flex', justifyContent: "space-between"}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <CustomImage src={s.user.image} width={'32px'} height={'32px'} borderRadius={'20px'}/>
                            <div style={{display: 'flex', flexDirection: "column",marginLeft: '10px'}}>
                                <h3 style={{color: 'white', fontSize: "15px", margin: 0}}>{s.user.name}</h3>
                                <h3 style={{fontSize: "10px",color: '#707070',margin: 0}}>{s.requestedAt}</h3>
                            </div>
                        </div>
                        {
                            s?.view?.access &&
                            <div style={{display: "flex"}}>
                                <ImgPlaceholder style={{marginRight: "10px"}}/>
                                <h1 style={{margin: 0, color: "#74D654", fontSize: "15px"}}>{text['Song Image Available']}</h1>
                            </div>
                        }
                    </div>
                    <h3 style={{color: 'white', fontSize: "25px",marginTop: "10px",marginBottom: '5px'}}>{s.song.name}</h3>
                    <h3 style={{color: 'white', fontSize: '15px',marginTop: 0}}>{s.song.artist.name}</h3>
                    <h3 style={{color: "#B3B3B3", fontSize: "13px"}}>{s.description}</h3>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{display: "flex"}}>
                            <div style={{display: 'flex', alignItems: "center", marginRight: "30px"}}>
                                <ButtonBase style={{width: "25px", height: "25px", marginRight: "5px"}}>
                                    {s.user_vote === 'up' ? <UpVoteOnIcon/> : <UpVoteOffIcon/>}
                                </ButtonBase>
                                <h3 style={{fontSize: '17px', color: '#868686'}}>{formatNumber(s.up.count)}</h3>
                            </div>
                            <div style={{display: 'flex', alignItems: "center"}}>
                                <ButtonBase style={{width: "25px", height: "25px",marginRight: "5px"}}>
                                    {s.user_vote === 'down' ? <DownVoteOnIcon/> : <DownVoteOffIcon/>}
                                </ButtonBase>
                                <h3 style={{fontSize: '17px', color: '#868686'}}>{formatNumber(s.down.count)}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                {renderLyricsImg()}
                <div style={{padding: "0 20px"}}>
                    <h1 style={{color: "#B3B3B3", fontSize: "13px", margin: "10px 0"}}>{text['Upload by']} - </h1>
                    <div style={{display: 'flex', alignItems: 'center'}} onClick={() => handleUserClick(s?.contributor?.user_id)}>
                        <CustomImage src={s?.contributor?.image} width={'32px'} height={'32px'} borderRadius={'20px'}/>
                        <div style={{display: 'flex', flexDirection: "column",marginLeft: '10px'}}>
                            <h3 style={{color: 'white', fontSize: "15px", margin: 0}}>{s?.contributor?.name}</h3>
                            <h3 style={{fontSize: "10px",color: '#707070',margin: 0}}>{s?.contributor?.date}</h3>
                        </div>
                    </div>
                </div>
            </>
            }

            {
                s && !loading &&
                <div style={{display: 'flex', justifyContent: "space-between", padding: "0 20px", margin: "20px 0"}}>
                    <CustomButton style={{backgroundColor: "#EF3333"}} onClick={() => showModal("delete")}>{text.Delete}</CustomButton>
                    <CustomButton style={{backgroundColor: "#74D654"}} onClick={() => showModal("accept")}>{text.Accept}</CustomButton>
                </div>
            }
        </div>
    </div>
  )
}

import React, { useState } from 'react'
import BackHeader from '../CommonComponents/BackHeader'

import CustomTextArea from '../CommonComponents/CustomTextArea'
import { Form, message } from 'antd'
import CustomPhotoUpload from '../CommonComponents/CustomPhotoUpload'
import CustomInput from '../CommonComponents/CustomInput'
import CustomButton from '../CommonComponents/CustomButton'
import Footer from '../CommonComponents/Footer'
import api from '../../api/apiClient'
import { endpoints } from '../../api/endpoints'
import Loader from '../CommonComponents/Loader'
import { useLocation } from 'react-router-dom'
import { ProfileType } from '../../types'
import { useStore } from '../../store'
import config from '../../config'

export default function EditProfile() {
  const {store} = useStore();
  const text = store.text;
  const location = useLocation();
  const profile: ProfileType = location.state.profile;
  const [loading, setLoading] = useState(false);
  const handleSave = async (values: {
    changedName: string,
    editBio: string,
    profileImg: string,
    coverImg: string
  }) => {
    setLoading(true);
    const params = {
      name: values.changedName,
      bio: values.editBio,
      avatar: values.profileImg,
      coverPhoto: values.coverImg
    };
    const result : any = await api.put(endpoints.user.updateProfile,params);
    setLoading(false);
    if(result.code === '200'){
      message.success(result.message);
    }else{
      message.error(result.message);
    }
  }

  const coverImgWidth = window.innerWidth > Number(config.screenWidth.split('px')[0]) ? config.screenWidth : "100vw"; 
  return (
    <div style={{position: "relative"}}>
        <BackHeader/>
        <Form
            name="basic"
            labelCol={{
            span: 8,
            }}
            wrapperCol={{
            span: 16,
            }}
            style={{
            fontFamily: "inherit"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={handleSave}
            autoComplete="off"
        >
          <div style={{position: 'absolute',width: "100%", top: 0}}>
            <Form.Item
              name="coverImg"
              wrapperCol = {{span: 24}}
              style={{padding: "0",color: "white", margin: 0, border: 0}}
              initialValue={profile?.coverPhoto}
            >
              <CustomPhotoUpload aspectRadio={414/157} alwaysDisplayUploadOutline={true}  displayText={false} circle={false} style={{width: coverImgWidth,height: "157px",borderRadius: 0}}/>
            </Form.Item>
          </div>
          <div style={{position: "absolute", width: "100%", top: "110px"}}>
            <div style={{display: "flex",justifyContent: 'space-between',alignItems: "center", position: "relative", padding: "0 17px"}}>
                <Form.Item
                  name="profileImg"
                  wrapperCol = {{span: 24}}
                  style={{padding: "",color: "white",marginTop: "18px"}}
                  initialValue={profile.avatar}
                >
                  <CustomPhotoUpload displayText={false} alwaysDisplayUploadOutline={true} text='Change' style={{width: "100px",height: "100px",border: "1px solid white"}}/>
                </Form.Item>
                <div style={{marginTop: "20px"}}>
                    <CustomButton type='submit' style={{padding: "10px 20px", width: "111px",margin: 0}} active>{loading ? <Loader btn/> : text.Edit}</CustomButton>
                </div>
            </div>
          </div>
          <div style={{padding: "0 17px"}}>
              <div style={{height: "170px"}}></div>
              <Form.Item
                name="changedName"
                wrapperCol = {{span: 24}}
                hasFeedback
                rules={[
                    {
                    required: true,
                    message: `!Name is required`,
                    },
                    {
                      validator: (_, value) =>
                        value && value.length > 35
                          ? Promise.reject(new Error('Username is too long'))
                          : Promise.resolve(),
                    },
                ]}
                style={{padding: "",color: "white",margin: "20px 0 35px 0"}}
                initialValue={profile.name}
              >
                <CustomInput placeholder='Change Name...'    />
              </Form.Item>
          
              <Form.Item
                name="editBio"
                wrapperCol = {{span: 24}}
                style={{color: "white",margin: "35px 0 18px 0"}}
                initialValue={profile.bio}
                rules={[
                  {
                    validator: (_, value) =>
                      value && value.length > 300
                        ? Promise.reject(new Error('Bio is too long'))
                        : Promise.resolve(),
                  },
                ]}
              >
              <CustomTextArea  placeholder='Enter Your Bio...'/>
              </Form.Item>
          
          </div>
        </Form>
        <Footer/>
    </div>
  )
}

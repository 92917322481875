import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import PullToRefresh from 'react-simple-pull-to-refresh';

import Footer from "../CommonComponents/Footer";
import Carousel from './Carousel';
import SongsSlider from './SongsSlider';
import ArtistsSlider from './ArtistsSlider';
import { useStore } from '../../store';
import { NotiService } from '../../noti/noti';
import { endpoints } from '../../api/endpoints';
import api from '../../api/apiClient';
import { ArtistDataType, CollectionDataType, NotiListsType, SongDataType } from '../../types';
import { getNotificationsFromStorage, saveNotificationsToStorage } from '../../utils';
import { useNavigate } from 'react-router-dom';
import TransitionWrapper from '../CommonComponents/TransitionWrapper';

function Home() {
  const {store, updateStoreFunc} = useStore();
  const {collections, latestSongs, artists} = store;

  const [loading, setLoading] = useState(false);
  const [existUnreadNoti, setExistUnreadNoti] = useState(false);

  const navigate = useNavigate();

  const getCollections = async () => {
    const result: any = await api.get(endpoints.collection.getCollection);
    if(result.code !== '200') return;
    const data: CollectionDataType = result.data;
    updateStoreFunc('collections',data);
  }

  const getArtists = async () => {
    const result: any = await api.get(endpoints.common.search + `?current=${1}&limit=10&type=${'artist'}&keyword=`);
    if(result.code !== '200') return;
    const data: ArtistDataType = result.data;
    updateStoreFunc('artists',data);
  }

  const getLatestSongs = async () => {
    const result: any = await api.get(endpoints.song.latestSong + `?current=${1}&limit=10`);

    if(result.code !== '200') return;
    const data: SongDataType = result.data;
    updateStoreFunc('latestSongs',data);
  }

  const handleLoading = (value: boolean) => {
    setLoading(value);
  };

  const getNotiLists = async (forceGet: boolean = false, read: boolean = false) => {
    if(!forceGet && store.notiLists){
      setExistUnreadNoti(checkUnreadNoti(store.notiLists));
      return;
    }

    const result : any = await api.get(endpoints.pushNoti.getNotiLists);
    if(result.code !== "200") return;
    const data: NotiListsType = result.data;

    const storedNotifications = getNotificationsFromStorage();

    for (const [key, value] of Object.entries(data)) {
        data[key] = data[key].map((noti) => ({
            ...noti,
            read: storedNotifications[key]?.filter(sn => sn.id === noti.id)[0]?.read || read,
        }));;
    };

    setExistUnreadNoti(checkUnreadNoti(data));
    updateStoreFunc('notiLists',data);
    saveNotificationsToStorage(data);
  }

  const checkUnreadNoti = (notiLists: NotiListsType) => {
    for (const [key, notifications] of Object.entries(notiLists)) {
      for (const noti of notifications) {
        if (!noti.read) {
          return true; 
        }
      }
    }
    return false;
  };

  const handleNotiServiceEvent = (event: string, data: any) => {
      switch (event) {
          case 'registration':
              break;
          case 'registrationError':
              break;
          case 'pushNotificationReceived':
              getNotiLists(true);
              break;
          case 'pushNotificationActionPerformed':
              let page = data.notification.data?.page;
              page = JSON.parse(page);
              if(page?.name === "songDetail"){
                const songId = page?.data?.songId;
                const chordId = page?.data?.chordId;
                navigate("/songDetail",{state: {song_id: songId, chord_id: chordId}});
              }else if( page?.name === "songRequest"){
                const songRequestId = page?.data?.songRequestId;
                navigate(`/songRequests/detail`,{state: {songRequestId}});
              }else{
                navigate("/home/notiLists");
              }
              getNotiLists(true, true);
              break;
          default:
              console.warn('Unhandled event:', event);
      }
  };

  const handleRefresh = async () => {
    setLoading(true);
    await getCollections();
    await getArtists();
    await getLatestSongs();
    await getNotiLists(true);
    setLoading(false);
  }

  const getDataFirstTime = async () => {
    if(!collections || !artists || !latestSongs){
      setLoading(true);
      await getCollections();
      await getArtists();
      await getLatestSongs();
      setLoading(false)
    }
    await getNotiLists();
  }

  const checkAndCallDailyClaimApi = async () => {
    const lastCallDate = localStorage.getItem("lastDailyClaimApiCallDate");
    const today = new Date().toLocaleDateString();
    if (!lastCallDate || lastCallDate !== today) {
      const result : any = await api.get(endpoints.points.dailyClaim);
      if(result.code === "200") localStorage.setItem("lastDailyClaimApiCallDate", new Date().toLocaleDateString());
    }
  };


  useEffect(() => {
    checkAndCallDailyClaimApi();
    getDataFirstTime();
    const noti = new NotiService(handleNotiServiceEvent);
    noti.init();
  }, [])

  return (
    <div >
      <TransitionWrapper>
        <PullToRefresh onRefresh={handleRefresh} resistance={4} pullingContent={<></>}>
          <div className="scrollable-y remove-scrollbar" style={{height: store.screenHeight}} >
            <>
              <Carousel collections={collections} loading={loading} existUnreadNoti={existUnreadNoti}/>
              <SongsSlider latestSongs={latestSongs} loading={loading}/>
              <ArtistsSlider artists={artists} loading={loading}/>
            </>
          </div>
        </PullToRefresh>
      </TransitionWrapper>
      <Footer/>
    </div>
  );
}



export default Home;
import { FacebookLogin } from '@capacitor-community/facebook-login';
import config from '../config';

export class FacebookService {
    private static instance: FacebookService;

    constructor() {
        if (FacebookService.instance) {
            return FacebookService.instance;
        }
        this.init();
        FacebookService.instance = this;
    }

    private init() {
        FacebookLogin.initialize({ appId: config.facebook.appId });
    }

    async login() {
        const FACEBOOK_PERMISSIONS = [
            'email',
        ];
        try {
            const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
            console.log('fb response: ',result);
              
            if (result.accessToken) {
                return result.accessToken.token
            }else{
                return null;
            }
        } catch (err) {
            console.log("err from facebook: ", err)
            return null;            
        }
    }

    logout(){
        FacebookLogin.logout();
    }
}
import React, { useState, useCallback } from 'react';
import { Upload, Button, UploadProps, UploadFile, message, Modal, Slider, GetProp } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import Cropper from 'react-easy-crop';

import ImgPreviewPlaceholder from '../../assets/icons/image-preview-placeholder.png';
import { UploadChangeParam } from 'antd/es/upload';
import { endpoints } from '../../api/endpoints';
import api from '../../api/apiClient';

interface CustomPhotoUploadProps {
  value?: string;
  onChange?: (tags: string) => void;
  circle?: boolean;
  text?: string;
  displayText?: boolean;
  alwaysDisplayUploadOutline?: boolean;
  aspectRadio?: number;
  style?: React.CSSProperties;
  outlineSideText?: string;
  showPreview?: boolean;
  useCropper?: boolean;
}

const CustomPhotoUploadType2: React.FC<CustomPhotoUploadProps> = ({ value = "", onChange, circle = true, text = 'Add photo', style, displayText = true, alwaysDisplayUploadOutline = false, aspectRadio = 1, outlineSideText, showPreview = true, useCropper = true}) => {
  const [imageUrl, setImageUrl] = useState<string>(value);
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null); // it is image base 64
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedArea, setCroppedArea] = useState(null);
  const [cropModalVisible, setCropModalVisible] = useState(false);
  const [zoom, setZoom] = useState(1);
  const handleUpload = async (info: UploadChangeParam<UploadFile<any>>) => {
    const file = info.fileList[0].originFileObj;
    const base64Img = await getBase64(file as FileType);
    setImageSrc(base64Img);
    if(!useCropper){
      setLoading(true);
      // const result: any = await api.post(endpoints.storage.upload, { base64: base64Img });
      const formData = new FormData();

      formData.append('file', file as Blob);

      const result : any = await api.post(endpoints.storage.upload, formData, {headers: {
        'Content-Type': 'multipart/form-data',
      }});

      if (result.code === '200') {
        setLoading(false);
        setImageUrl(base64Img);
        onChange?.(result.data);
      } else {
        message.error(result.message);
      }
    }else{
      setCropModalVisible(true);
    }
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedArea(croppedAreaPixels);
  };

  const handleCropConfirm = async () => {
    setCropModalVisible(false);
    setLoading(true);
    const croppedImg = await getCroppedImg(imageSrc as string, croppedArea); 
    // const result: any = await api.post(endpoints.storage.upload, { base64: croppedImg });
    const formData = new FormData();

    formData.append('file', croppedImg as Blob);

    const result : any = await api.post(endpoints.storage.upload, formData, {headers: {
      'Content-Type': 'multipart/form-data',
    }});
    
    setLoading(false);

    if (result.code === '200') {
      setImageUrl(result.data);
      onChange?.(result.data);
    } else {
      message.error(result.message);
    }
  };

  type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const getCroppedImg = async (imageSrc: string, croppedAreaPixels: any) => {
    const image = new Image();
    image.src = imageSrc;
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      image.onload = () => {
        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );
        resolve(canvas.toDataURL('image/jpeg'));
      };
    });
  };

  const imgContainerStyle = circle ? styles.imageContainer : { ...styles.imageContainer, ...styles.imageSquare };

  return (
    <div style={styles.container}>
      <Upload
        name="avatar"
        showUploadList={false}
        beforeUpload={() => false}
        onChange={handleUpload}
        maxCount={1}
        accept="image/*"
      >
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: "column" }}>
          <div style={{ ...imgContainerStyle, ...style }}>
            {imageUrl && showPreview && !loading ? (
              <>
                {alwaysDisplayUploadOutline &&
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center", width: "100%",padding: '10px'}}>
                    <div style={{display: 'flex', alignItems: "center"}}>
                      <UploadOutlined type='link' style={{...styles.icon, color: imageUrl ? "#007BFF" : "#424242"}} />
                      {outlineSideText && <h3 style={{fontSize: "15px", margin: "0 0 0 20px", color: imageUrl ? "#007BFF" : "white"}}>{outlineSideText}</h3>}
                    </div>
                    <img src={imageUrl} style={{width: "42px", height: "42px",marginLeft: "10px"}}/>
                  </div>
                }
              </>
              
            ) : (
              <div style={styles.placeholder}>
                {loading ? 
                <div style={{display: "flex", justifyContent: 'center', width: "100%"}}><LoadingOutlined/></div> : 
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center", width: "100%"}}>
                  <div style={{display: 'flex', alignItems: "center"}}>
                    <UploadOutlined type='link' style={{...styles.icon, color: imageUrl ? "#007BFF" : "#424242"}} />
                    {outlineSideText && <h3 style={{fontSize: "15px", margin: "0 0 0 20px", color: imageUrl ? "#007BFF" : "white"}}>{outlineSideText}</h3>}
                  </div>
                  <img src={ImgPreviewPlaceholder} style={{marginLeft: "10px"}}/>
                </div>
                }
              </div>
            )}
          </div>
          {displayText && <Button type="link" style={styles.button}>{text}</Button>}
        </div>
      </Upload>

      <Modal
        open={cropModalVisible}
        onCancel={() => setCropModalVisible(false)}
        onOk={handleCropConfirm}
        okText="Save"
        cancelText="Cancel"
        width={400}
        closeIcon={false}
      >
        <div style={{ position: 'relative', height: 400, background: '#333'}}>
          {imageSrc && (
            <Cropper
              style={{cropAreaStyle: {borderRadius: circle ? "50%" : "0"}}}
              onCropChange={setCrop}
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspectRadio}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            
          )}
        </div>
        <Slider
          min={1}
          max={3}
          step={0.1}
          value={zoom}
          onChange={setZoom}
          style={{ marginTop: 16 }}
        />
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  } as React.CSSProperties,
  imageContainer: {
    width: 140,
    height: 140,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
    backgroundColor: '#1e1e1e',
    position: "relative"
  } as React.CSSProperties,
  imageSquare: {
    borderRadius: '10px',
  } as React.CSSProperties,
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  } as React.CSSProperties,
  placeholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    padding: '10px'
  } as React.CSSProperties,
  icon: {
    fontSize: '32px',
    color: '#007BFF',
  } as React.CSSProperties,
  button: {
    color: '#007BFF'
  } as React.CSSProperties,
};

export default CustomPhotoUploadType2;

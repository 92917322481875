import { ButtonBase } from '@mui/material';
import React, { UIEventHandler } from 'react';
import InfiniteScrollWrapper from './InfinityScrollWrapper';
import { useNavigate } from 'react-router-dom';

import { AlbumType, ArtistType, PlaylistType, SongType, UserType } from '../../types';
import Loader from './Loader';
import CustomImage from './CustomImage';

import {ReactComponent as PlusCircle} from '../../assets/icons/plus-circle-on.svg'
import {ReactComponent as VersionIcon} from '../../assets/icons/version.svg'
import {ReactComponent as PublicIcon} from '../../assets/icons/public.svg'
import {ReactComponent as PrivateIcon} from '../../assets/icons/private.svg'
import {ReactComponent as EditIcon} from '../../assets/icons/edit-icon.svg'
import { useStore } from '../../store';
import { getDecoded } from '../../utils';

// favourite logic is removed. But left to remove in frontend

interface CustomListProps {
  data?: any;
  searchQuery?: string;
  tags?: string[];
  type?: 'Song' | 'Artist' | 'Album'| 'User' | 'Playlist';
  selectedTag?: string;
  onSearchChange?: (query: string) => void;
  onTagSelect?: (tag: string) => void;
  onFavoriteToggle?: (songId: number, chordId: number, favorite: string) => void;
  showSearchBar?: boolean;
  showTagBar?: boolean;
  showFavorite?: boolean;
  loading: boolean; // for the inital loading
  handleFetchMore: () => void;
  hasMore: boolean;
  scrollHeight?: number;
  artistHeader?: boolean;
  artistHeaderData?: ArtistType;
  albumHeader?: boolean;
  albumHeaderData?: AlbumType;
  handleScroll?: any;
  scrollPos?: number;
  showVersions?: boolean
}

const CustomList: React.FC<CustomListProps> = ({
  data,
  searchQuery = '',
  tags = [],
  type = 'Song',
  selectedTag = tags[0],
  onSearchChange,
  onTagSelect,
  onFavoriteToggle,
  showSearchBar = false,
  showTagBar = false,
  loading,
  handleFetchMore,
  hasMore,
  scrollHeight = 200,
  artistHeader = false,
  artistHeaderData,
  albumHeader = false,
  albumHeaderData,
  handleScroll,
  scrollPos,
  showVersions = true
}) => {

  const {store, showPlaylist} = useStore();
  const text = store.text;
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
       {showSearchBar && (
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => onSearchChange && onSearchChange(e.target.value)}
          style={styles.searchBar}
          spellCheck={false}
        />
      )}
      {showTagBar && (
        <div style={styles.tagBar} className='remove-scrollbar'>
          {tags.map((tag, index: number) => (
            <ButtonBase
              key={index}
              onClick={() => onTagSelect && onTagSelect(tag)}
              style={{
                ...styles.tagButton,
                ...(tag === selectedTag ? styles.selectedTagButton : {}),
              }}
            >
              {(text as any)[tag]}
            </ButtonBase>
          ))}
        </div>
      )}
      {artistHeader && (
        <div key={artistHeaderData?.artist_id} style={styles.item}>
          <CustomImage src={albumHeaderData?.image || ''} alt={albumHeaderData?.name} width={'70px'} height={'70px'} borderRadius="50px"/>
          <div style={styles.itemInfo}>
            <h3 style={styles.itemTitle}>{artistHeaderData?.name}</h3>
            <div style={styles.songCount}>
              {artistHeaderData?.totalSongs} <span style={{color: '#3373EF'}}>{text.Song}</span>
            </div>
          </div>
        </div>
      )}
      {albumHeader && (
        <div key={albumHeaderData?.id} style={styles.songItem} >
          <CustomImage src={albumHeaderData?.image || ''} alt={albumHeaderData?.name} width={'100px'} height={'100px'} borderRadius="5px"/>
          <div style={styles.songInfo}>
            <h3 style={styles.songTitle}>{albumHeaderData?.name}</h3>
            <p style={{...styles.username,height: "62px",overflow: "hidden",textOverflow: "ellipsis",display: "-webkit-box",WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>{albumHeaderData?.description}</p>
          </div>
        </div>
      )}
        <InfiniteScrollWrapper
          height={scrollHeight}
          fetchMoreData={handleFetchMore}
          hasMore={hasMore}
          dataLength={data?.by?.length || 0}
          loader={<Loader btn/>}
          handleScroll={handleScroll}
          scrollPos={scrollPos}
        >
          <div style={styles.listContainer}>
            { !loading && (type === 'Song') && 
              data?.by?.map((song : SongType,index: number) => (
                <div key={index}  style={{display: "flex",justifyContent: "space-between",alignItems: 'start', position: "relative"}}>
                  <div style={styles.songItem}>
                    <CustomImage src={song.image_url} alt={song?.name} width={'100px'} height={'100px'} borderRadius="5px" onClick={()=> {navigate("/songDetail",{state: song});}}/>
                    <div style={styles.songInfo} onClick={()=> {navigate("/songDetail",{state: song});}}>
                      <h3 style={styles.songTitle}>{song?.name}</h3>
                      <p style={styles.artistName}>{song?.artist?.name}</p>
                      <div>
                        <p onClick={(e) => {
                          { 
                            e.stopPropagation();
                            if(Number(song.user.user_id) === getDecoded().userId){
                              navigate('/profile');
                              return;
                            }
                            const obj = {userId: song.user.user_id};
                            navigate('/userProfile',{state: obj});
                          }
                        }} style={styles.username}>{song?.user?.name}</p>
                      </div>
                    </div>
                  </div>
                  <ButtonBase style={{marginTop: "8px"}} onClick={() => showPlaylist(song.song_id, song.chord_id, false)}>
                    <PlusCircle/>
                  </ButtonBase>
                  {showVersions && <ButtonBase 
                    style={{position: "absolute", right: 0, top:"75px"}}
                    onClick={()=> navigate("/songVersions",{state: {songId: song.song_id}})}
                  >
                    <VersionIcon/>
                  </ButtonBase>}
                </div>
              ))
            }
            { !loading && (type === 'Playlist') && 
              data?.by?.map((p : PlaylistType,index: number) => (
                <div key={index}  style={{display: "flex",justifyContent: "space-between",alignItems: 'start', position: "relative"}} onClick={() => navigate('/playlist',{state: {playlistId: p.id, playlistName: p.name}})}>
                  <div style={styles.songItem}>
                    <CustomImage src={p?.image} alt={p?.name} width={'100px'} height={'100px'} borderRadius="5px" />
                    <div style={styles.songInfo} >
                      <h3 style={styles.songTitle}>{p?.name}</h3>
                      <p style={{margin: '1px 0 5px 0',fontSize: '15px', color: '#3373EF'}}>{p?.total} {text['Song']}</p>
                      <div>
                        {
                          p?.status === "public"
                          ? <PublicIcon/>
                          : <PrivateIcon/>
                        }
                      </div>
                    </div>
                  </div>
                  {p?.editable === 'true' && <ButtonBase style={{marginTop: "8px"}} onClick={(e)=> {
                    e.stopPropagation();
                    showPlaylist(undefined, undefined, true, p.id )
                  }}>
                    <EditIcon/>
                  </ButtonBase>}
                </div>
              ))
            }
            { ! loading && type === "Album" && 
              data?.by?.map((album : AlbumType, index: number) => (
                <div key={index} style={styles.songItem} onClick={() => 
                  navigate('/home/songListsBySpecificId',{state: {selectedTag, headerData: album}})
                }>
                  <CustomImage src={album.image} alt={album?.name} width={'100px'} height={'100px'} borderRadius="5px"/>
                  <div style={styles.songInfo}>
                    <h3 style={styles.songTitle}>{album?.name}</h3>
                    <p style={{...styles.username,height: "62px",overflow: "hidden",textOverflow: "ellipsis", display: "-webkit-box",WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>{album?.description}</p>
                  </div>
                </div>
              ))
            }
            { !loading && ( type === 'Artist') &&
              data?.by?.map((artist: ArtistType, index: number) => (
                <div key={index} style={styles.item} onClick={() => 
                {
                  const obj = {selectedTag, headerData: artist};
                  navigate('/home/songListsBySpecificId',{state: obj})
                }
                }>
                  <CustomImage src={artist.image} alt={artist?.name} width={'70px'} height={'70px'} borderRadius="50px" style={{border: "1.5px solid rgba(255, 255, 255, .3)"}}/>
                  <div style={styles.itemInfo}>
                    <h3 style={styles.itemTitle}>{artist?.name}</h3>
                    <div style={styles.songCount}>
                      {artist.totalSongs} <span style={{color: '#3373EF'}}>{text.Song}</span>
                    </div>
                  </div>
                </div>
            ))} 
            { !loading && ( type === 'User') &&
              data?.by?.map((user: UserType, index: number) => (
                <div key={index} style={styles.item} onClick={() => 
                {
                  if(user.user_id === getDecoded().userId){
                    navigate('/profile');
                    return;
                  }
                  const obj = {userId: user.user_id};
                  navigate('/userProfile',{state: obj});
                }
                }>
                  <CustomImage src={user.avatar} alt={user?.name} width={'70px'} height={'70px'} borderRadius="50px" style={{border: "1.5px solid rgba(255, 255, 255, .3)"}}/>
                  <div style={styles.itemInfo}>
                    <h3 style={styles.itemTitle}>{user?.name}</h3>
                    <div style={styles.songCount}>
                      {user.totalSongs} <span style={{color: '#3373EF'}}>{text.Song}</span>
                    </div>
                  </div>
                </div>
            ))} 
            {
              !hasMore && ( !data) && 
              <h1 style={{color: "gray", textAlign: "center",fontSize: "20px"}}>{text['No results found']}</h1>
            }
          </div>
        </InfiniteScrollWrapper>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    padding: '10px',
  } as React.CSSProperties,
  searchBar: {
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '20px',
    border: '1px solid #ccc',
    backgroundColor: '#D9D9D9',
    color: 'black',
    fontSize: '16px',
    outline: "none"
  } as React.CSSProperties,
  tagBar: {
    display: 'flex',
    marginBottom: '15px',
    marginTop: "5px",
    width: '100%',
    overflowX: "scroll",
  } as React.CSSProperties,
  tagButton: {
    padding: '8px 12px',
    marginRight: '10px',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#3A3A3C',
    color: '#FFFFFF',
    cursor: 'pointer',
    whiteSpace: 'nowrap' as const,
  } as React.CSSProperties,
  selectedTagButton: {
    backgroundColor: '#0A84FF',
  } as React.CSSProperties,
  listContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
  } as React.CSSProperties,
  songItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    padding: '10px 0',
    borderRadius: '10px',
  } as React.CSSProperties,
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    padding: '10px 0',
    borderRadius: '10px',
  } as React.CSSProperties,
  itemInfo: {
    flex: 1,
    marginLeft: "10px"
  } as React.CSSProperties,
  itemTitle: {
    margin: '0',
    fontSize: '16px',
    color: '#FFFFFF',
  } as React.CSSProperties,
  songInfo: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
    height: "100px",
    justifyContent: "space-between",
    alignItems: 'start'
  } as React.CSSProperties,
  songTitle: {
    margin: '0',
    fontSize: '16px',
    color: '#FFFFFF',
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: '180px',
    whiteSpace: 'nowrap'
  } as React.CSSProperties,
  artistName: {
    margin: '1px 0 5px 0',
    fontSize: '15px',
    color: '#A0A0A0',
  } as React.CSSProperties,
  username: {
    margin: '15px 0 0 0',
    fontSize: '14px',
    fontWeight: "bold",
    color: '#3373EF',
  } as React.CSSProperties,
  rating: {
    fontSize: '14px',
    color: '#FFD700',
  } as React.CSSProperties,
  songCount: {
    fontSize: '15px',
    color: 'white',
    fontWeight: "bold",
    marginTop: "5px"
  } as React.CSSProperties,
  favoriteButton: {
    fontSize: '16px',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#FF3B30',
    cursor: 'pointer',
    marginTop: "20px"
  } as React.CSSProperties,
};

export default CustomList;

import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import Footer from '../CommonComponents/Footer'
import { useStore } from '../../store';
import NewSingerInputForm from './NewSingerInputForm';
import NewSongInputForm from './NewSongInputForm';
import Header from './Header';
import NewAlbumInputForm from './NewAlbumInputForm';
import { endpoints } from '../../api/endpoints';
import api from '../../api/apiClient';
import { message } from 'antd';
import TransitionWrapper from '../CommonComponents/TransitionWrapper';

export default function DataEntry() {
  const [activeHeader,setActiveHeader] = useState('new song');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const {songId, artistId, songName, artistName} =  location?.state || {};

  const {store, updateStoreFunc} = useStore();

  const handleNewSongFinish = async (value: {
    albumId: number,
    composerId: number,
    singerId: number,
    singerName: string,
    songName: string,
    songNameMyanmar: string,
    songNameEnglish: string,
    songPhoto: string,
    editWithChordEditor: boolean,
    lyricsImg: string,
    tags: string[],
    songId: number
  }) => {

    if(!value.lyricsImg && !value.editWithChordEditor){
      return message.info("Choose Lyrics Img or Edit with chord editor");
    }
    
    let songInfo = {
      albumId: value.albumId,
      composerId: value.composerId,
      singerId: value.singerId,
      singerName: value.singerName,
      songName: value.songName,
      songNameMyanmar: value.songNameMyanmar,
      songNameEnglish: value.songNameEnglish,
      songPhoto: value.songPhoto,
      tags: value?.tags?.join(',') || "",
      chordUid: null,
      chordImg: value.lyricsImg,
      songId: value.songId
    };
    localStorage.setItem('songInfo', JSON.stringify(songInfo));

    if(value.lyricsImg && !value.editWithChordEditor){ // can publish if user only upload image.
      const params = {
          name: songInfo.songName,
          nameMm: songInfo.songNameMyanmar,
          nameEn: songInfo.songNameEnglish,
          lyrics: "",
          artistId: songInfo.singerId,
          albumId: songInfo.albumId,
          tags: songInfo.tags,
          composerId: songInfo.composerId,
          status: '1',
          musicLink: 'example.com',
          image: songInfo.songPhoto,
          key: `ABC`,
          chordPro: "",
          chordImg: songInfo.chordImg,
          songId: value.songId
        };
        const result : any= await api.post(endpoints.song.addSong, params);
        if(result.code === "200") {
          message.success('Successfully added new song');
          if(songId) { //it means user come from song request
            navigate("/songRequests");
            return;
          }
          if(typeof result.data === 'object') {
            navigate("/songDetail",{state: result.data});
          }else{
            navigate('/dataEntry',{replace: true})
          }
        }else{
          message.error(result.message)
        }
        return;
    }

    navigate('/dataEntry/addLyrics',{state: {songInfo}});
  }

  const handleNewSingerFinish = async (value: {
    gender: string,
    remark: string,
    singerName: string,
    singerNameMyanmar: string,
    singerNameEnglish: string,
    singerPhoto: string,
    artistIds: number[]
  }) => {
    const params = {
      name: value.singerName,
      nameMm: value.singerNameMyanmar,
      nameEn: value.singerNameEnglish,
      type: value.gender,
      image: value.singerPhoto,
      remark: value.remark,
      artistIds: value.artistIds
    };
    if(!value.singerPhoto) return message.info("Please upload image!");
    setLoading(true);
    const result: any = await api.post(endpoints.artist.addArtist,params);
    setLoading(false);
    if(result.code === '200') {
      message.success("Successfully add new artist")
    }else{
      message.error(result.message);
    }
  }

  const handleNewAlbumFinish = async (value : {
    albumName: string;
    albumPhoto: string;
    description: string
  }) => {
    const params = {
      name: value.albumName,
      image: value.albumPhoto,
      description: value.description
    };
    // if(!value.albumPhoto) return message.info("Please upload image!")
    setLoading(true);
    const result: any = await api.post(endpoints.alblum.addAlbum,params);
    setLoading(false);
    if(result.code === '200') {
      message.success("Successfully add new artist")
    }else{
      message.error(result.message);
    }
  }

  const handleChangeHeader = (v: string) => setActiveHeader(v);

  return (
    <div className="scrollable-y remove-scrollbar" style={{height: store.screenHeight}}>
          <div style={{padding: "15px"}}>
            <Header handleChangeHeader={handleChangeHeader} activeHeader= {activeHeader}  />
        <TransitionWrapper>
            {activeHeader === 'new song' ?
              <NewSongInputForm handleNewSongFinish={handleNewSongFinish} fromSongRequest={{songId,artistId,songName,artistName}}/>
              :
             activeHeader === 'new singer' ?
              <NewSingerInputForm handleNewSingerFinish={handleNewSingerFinish} loading={loading}/>
              :
              <NewAlbumInputForm handleNewAlbumFinish={handleNewAlbumFinish} loading={loading}/>
            }
        </TransitionWrapper>
          </div>
        <Footer/>
    </div>
  )
}




